import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import { Hero, Breadcrumb } from "components/ui";
import { CardContainer, FlagCardContainer } from "components/containers/Cards";

const TopicsCommonEntries = ({ location, title, description, data, ...rest }) => {
  const createKey = title=>{
    let keyId = title.toLowerCase().replace(" ", "-");
    return keyId
  }
  return (
    <Layout location={location}>
        <SeoComponent title={title} />
        <div>
          <Hero
            img={rest.topicHeroImage}
            color={rest.color}
            >
              <Breadcrumb pathname={rest.pathname} />
              <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label={`Hero: ${title}`}>{title}</h1>
              <p>{description}</p>
            </Hero>
        </div>
        {data.map((resource, idx)=>{
          return resource.isHighlightedResource &&
          <FlagCardContainer
          key={`highlighted-resource-card-group-${idx+1}`}
          title={resource.title}
          resources={resource.data}
          containerStyle="blue-bg"
          headerColor="text-base-lightest"
          keyName="highlighted-resource"
          cardColor="bg-white"
          grid="desktop:grid-col-12"
        />
        })}
        {data.map((resource, idx)=>{
          let bgColorIndex = idx+1;
          return !resource.isHighlightedResource &&
            <CardContainer
            key={`topic-card-group-${idx+1}`}
            title={resource.title}
            resources={resource.data}
            keyName={createKey(resource.title)}
            containerStyle={bgColorIndex % 2 === 0 ? "": "light-gray-bg"}
            cardColor="bg-white"
            grid="desktop:grid-col-6"
            />
          })}
    </Layout>
  );
};



export default TopicsCommonEntries;
